var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DDGKnzd_dcrXfSxzbXXWr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { getAppConfig } from '@/config/env';

const { sentryEnvironment, sentryDsn } = getAppConfig();

Sentry.init({
  environment: sentryEnvironment,
  dsn: sentryDsn,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  tracesSampler: (samplingContext) => {
    // Do not send any traces for aws health-check
    if (
      samplingContext.request?.headers['user-agent'] === 'ELB-HealthChecker/2.0'
    ) {
      return false;
    }

    // Default sample rate (20%)
    return 0.2;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
